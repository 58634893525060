import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import '../styles/page-404.scss'

const NotFoundPage = () => {
  const intl = useIntl();
  return (
    <Layout contentType="internal">
      <SEO title={intl.formatMessage({ id: "not_found.seo" })} />
      <div className="container page-content page-404">
        <h2>Oops!...</h2>
        <h1>404</h1>
        <h3><FormattedMessage id="not_found.wrong_turn" /></h3>
        <p><FormattedMessage id="not_found.page_moved" /></p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
